// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import "~@angular/material/theming";
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** branding **/
  --branding-blue: #0a5cb9;
  --branding-teal: #22acef;
  --branding-green: #7cae40;
  --branding-pink: #cb2b75;
  --branding-dark: #243743;

  /* app color */
  --app-color: #d376fa;
  --app-color-hover: #e5f5ff;

  /* app gradient */
  --app-gradient: linear-gradient(
    0deg,
    hsla(269, 85%, 41%, 1) 0%,
    hsla(328, 75%, 45%, 1) 100%
  );
  --app-gradient-moz: -moz-linear-gradient(
    0deg,
    hsla(269, 85%, 41%, 1) 0%,
    hsla(328, 75%, 45%, 1) 100%
  );
  --app-gradient-webkit: -webkit-linear-gradient(
    0deg,
    hsla(269, 85%, 41%, 1) 0%,
    hsla(328, 75%, 45%, 1) 100%
  );
  --app-gradient-compat: hsla(269, 85%, 41%, 1);
  --app-gradient-color-1: hsla(269, 85%, 41%, 1);
  --app-gradient-color-2: hsla(328, 75%, 45%, 1);

  /* padding values */
  --padding-size-minimal: 4px;
  --padding-size-x-small: 8px;
  --padding-size-small: 12px;
  --padding-size-normal: 16px;
  --padding-size-large: 24px;
  --padding-size-x-large: 32px;
  --padding-size-huge: 48px;

  /* font size values */
  --font-size-x-small: 12px;
  --font-size-small: 14px;
  --font-size-normal: 16px;
  --font-size-large: 24px;
  --font-size-x-large: 32px;
  --font-size-huge: 48px;

  /* misc theme values */
  --border-radius-size-normal: 16px;

  /** contrast colors **/
  --header-color: #ffffff;
  --background-color: #ffffff;
  --background-highlight-color: rgba(var(--ion-color-primary-rgb), .1);
  --background-shading-color: #f7f7f7;
  --shading-color: #f7f7f7;
  --shading-highlight-color: #e0f5ff;
  --shading-active-color: #eeeeee;
  --item-color: #f2f2f2;
  --item-highlight-color: #e0f5ff;
  --item-active-color: #e6e6e6;
  --app-border-color: rgba(0, 0, 0, 0.2);
  --font-color: #000;
  --font-shade-color: rgba(0, 0, 0, 0.7);
  --font-fade: rgba(0, 0, 0, 0.5);

  --ion-color-primary: #157bc3;
  --ion-color-primary-rgb: 21, 123, 195;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #126cac;
  --ion-color-primary-tint: #2c88c9;

  // --ion-color-primary: #5448c8;
  // --ion-color-primary-rgb: 84, 72, 200;
  // --ion-color-primary-contrast: #ffffff;
  // --ion-color-primary-contrast-rgb: 255, 255, 255;
  // --ion-color-primary-shade: #4a3fb0;
  // --ion-color-primary-tint: #655ace;

  --ion-color-secondary: #7cae40;
  --ion-color-secondary-rgb: 124, 174, 64;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #6d9938;
  --ion-color-secondary-tint: #89b653;

  --ion-color-tertiary: #1496d2;
  --ion-color-tertiary-rgb: 20, 150, 210;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #1284b9;
  --ion-color-tertiary-tint: #2ca1d7;

  /** success **/
  --ion-color-success: #00995e;
  --ion-color-success-rgb: 0, 153, 94;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #008753;
  --ion-color-success-tint: #1aa36e;

  /** warning **/
  --ion-color-warning: #dba700;
  --ion-color-warning-rgb: 219, 167, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c19300;
  --ion-color-warning-tint: #dfb01a;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
}

/*
 * Dark Theme
 * -------------------------------------------
 */

body.dark {
  /** Dark: Contrast Colors **/
  --header-color: #202124;
  --app-border-color: rgba(191, 191, 191, 0.5);
  --background-color: #121212;
  --background-highlight-color: #353535;
  --background-shading-color: #000;
  --shading-color: #35363a;
  --shading-highlight-color: #4a4c53;
  --shading-active-color: #61636d;
  --item-color: #35363a;
  --item-highlight-color: #36373b;
  --item-active-color: #61636d;
  --font-color: #fff;
  --font-shade-color: rgba(255, 255, 255, 0.7);
  --font-fade-color: rgba(255, 255, 255, 0.5);

  /** Dark: success **/
  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  /** Dark: warning **/
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  /** Dark: danger **/
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  /** Dark: dark **/
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  /** Dark: medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** Dark: light **/
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

.md body.dark,
.ios body.dark {
  --ion-background-color: var(--background-color);
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: var(--font-color);
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: var(--item-color);

  --ion-toolbar-background: var(--header-color);

  --ion-tab-bar-background: var(--header-color);

  --ion-card-background: var(--item-color);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
}
