/**
 * ID: bh-global-css
 * Name: global.scss
 * Description: App-specific global themes. Applied after base and canned stylesheets applied.
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.all.scss, base.desktop.scss, base.mobile.scss, canned.scss
 * 2023-09-26 - MW - v5: Updated for bh-starter-i7
 */

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

/* Import Quill CSS */
@import '~quill/dist/quill.snow.css';

/* Fix for uncentered date field */
.mat-mdc-form-field-flex {
  align-items: center !important;
}

.mat-calendar-body-cell-content {
  border-style: none !important;
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  color: #fff;
}

.mat-datepicker-content .mat-calendar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  height: auto !important;
}

.editor-tools {
  padding: 16px;
  text-align: center;

  ion-button {
    min-width: 200px;
  }
}

.record-row {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  font-size: 14px;
  padding: 12px 0;
  max-width: 100%;
  // margin-bottom: 8px;
  // border-bottom: 1px solid var(--app-border-color);

  .record-label {
    min-width: 120px;
    margin-bottom: 8px;
  }

  .record-value {
    font-weight: bold;
    line-break: anywhere;
  }
}

@media (max-width: 767px) {
  .record-row {
    flex-direction: column;
  }
}

.content-block-inner,
.te-editor-content {
  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 0;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 600;
  }

  p {
    margin: 8px;
  }
}

.tag {
  display: inline-flex;
  background: rgba(var(--ion-color-primary-rgb), .1);
  padding: 8px 12px;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #000;
  text-decoration: none;
  font-weight: normal;
  text-transform: capitalize;

  &:hover {
    background: rgba(var(--ion-color-primary-rgb), .2);
  }

  ion-icon {
    margin-right: 8px;
    font-size: 20px;
    color: var(--ion-color-primary);

    &.action-icon {
      margin-right: 0;
    }

    &.end-icon {
      margin-left: 8px;
    }
  }

  a {
    display: inline-flex;
  }
}

quill-editor {
  display: block;
  width: 100%;

  .ql-toolbar.ql-snow {
    border: 1px solid transparent;
    border-top: 1px solid var(--app-border-color);
    padding: 12px 0;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor,
  .ql-snow .ql-editor {
    background: var(--background-color);
    border: 1px solid var(--app-border-color);
    border-radius: 8px;
    min-height: 200px;
    font-size: 16px;
  }

  .ql-snow .ql-tooltip.ql-editing input[type=text] {
    color: var(--font-color);
    background: var(--background-color);
  }
}

.event-thumbnail {
  background: rgba(var(--ion-color-primary-rgb), .1);
  border-radius: 100px;
  min-height: 48px;
  max-height: 48px;
  min-width: 48px;
  max-width: 48px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-left: 4px;

  span {
    font-size: 13px;
    font-weight: normal;
    display: block;
  }
}

.app-thumbnail {
  background: rgba(var(--ion-color-primary-rgb), .1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  margin-left: 4px;
}
